export const entitlements = {
  VUS: {
    FCSC_BASE: 'VUS.FCSC_Base',
    FCSC_PUBLIC: 'VUS.FCSC_PUBLIC',
    FLASHWARE_CALCULATION: 'VUS.FlashwareCalculation',
    FCSC_VORAB_DATA_IMAGE_DELETE: 'VUS.FCSC_VorabDataImageDelete',
    FCSC_SERIEN_DATA_EXPORT: 'VUS.FCSC_SerienDataExport',
    FCSC_UPLOAD_LIST: 'VUS.FCSC_UploadList',
    FCSC_UPLOADLIST_ADDINFO: 'VUS.FCSC_UploadList_Addinfo',
    FCSC_FLASHWARE_CALCULATION_EXPORT: 'VUS.FCSC_FlashwareCalculationExport',
    FCSC_TEST_FLASHWARE_CALCULATION: 'VUS.FCSC_TestFlashwareCalculation',
    FCSC_TEST_IMAGE_BUILD_DEPLOY: 'VUS.FCSC_TestImageBuildDeploy',
    FCSC_FLASHWARE_CALCULATION_CONFIGURATION:
      'VUS.FCSC_FlashwareCalculationConfiguration',
    FCSC_TEST_DATA_IMAGE_DELETE: 'VUS.FCSC_TestDataImageDelete',
    FCSC_FINLOG_CRYPTO: 'VUS.FCSC_FinlogCrypto',
    FCSC_TEST_DATA: 'VUS.FCSC_TestData',
    FCSC_VORAB_IMAGE_SMOKETEST_CONFIGURATION:
      'VUS.FCSC_VorabImageSmokeTestConfiguration',
    FCSC_SERIEN_IMAGE_DEPLOY: 'VUS.FCSC_SerienImageDeploy',
    FCSC_VORAB_DATA_IMPORT: 'VUS.FCSC_VorabDataImport',
    FCSC_VORAB_DATA: 'VUS.FCSC_VorabData',
    FCSC_TEST_DATA_EXPORT: 'VUS.FCSC_TestDataExport',
    FCSC_SERIEN_IMAGE_ROLLBACK: 'VUS.FCSC_SerienImageRollback',
    FCSC_VORAB_DATA_EXPORT: 'VUS.FCSC_VorabDataExport',
    FCSC_VORAB_IMAGE_BUILD_DEPLOY: 'VUS.FCSC_VorabImageBuildDeploy',
    FCSC_SERIEN_DATA: 'VUS.FCSC_SerienData',
    FCSC_TEST_DATA_IMPORT: 'VUS.FCSC_TestDataImport',
    FCSC_VORAB_FLASHWARE_CALCULATION: 'VUS.FCSC_VorabFlashwareCalculation',
    FCSC_OPEN_SEARCH_DASHBOARD: 'VUS.FCSC_OpenSearchDashboard',
    FCSC_VP5_TESTING: 'VUS.FCSC_VP5Testing',
    FCSC_VP5_VERSION: 'VUS.FCSC_VP5VERSION',
    FCSC_CRYPTO_WITHOUT_SUMS: 'VUS.FCSC_CryptoWithoutSums',
    FCSC_CRYPTO_WITH_SUMS: 'VUS.FCSC_CryptoWithSums',
    FCSC_SNS_READ:'VUS.FCSC_SNS_READ',
    FCSC_SNS_WRITE:'VUS.FCSC_SNS_WRITE',
    FCSC_FSF_MANIPULATION: 'VUS.FCSC_FSF_MANIPULATION',
    FCSC_FSF_DOWNLOAD_WITH_SUMS: 'VUS.FCSC_FDOWNLOAD_WITH_SUMS',
    FCSC_FSF_DOWNLOAD_WITHOUT_SUMS: 'VUS.FCSC_FDOWNLOAD_WITHOUT_SUMS',
    FCSC_FSF_LOGS : 'VUS.FCSC_FSF_LOGS',
    FCSC_TESTINGBUTTON: 'VUS.FCSC_TestingButton',
    FCSC_LOGS: 'VUS.FCSC_Logs',
  },
};

export function getEntitlements(entitlementGroup: any) {
  return Object.keys(entitlementGroup).map((element) => {
    return entitlementGroup[element];
  });
}
