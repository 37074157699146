import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnInit,
} from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AppService } from '@core/services/app.service';
import { StorageService } from '@core/services/storage.service';
import { entitlements } from '@env/entitlements';
import { paths } from '@env/paths';
import { TranslocoService } from '@ngneat/transloco';
import { Menu } from '@shared/models/Menu';
import { Spinner } from '@shared/models/spinner';
import { filter } from 'rxjs/operators';

import { SecurityService } from './core/services/security.service';
import { Application } from './shared/models/Application';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, AfterContentChecked {
  public application!: Application;
  public showNavigation = false;
  dataMang!: string;
  isLoading = false;
  loadingTitle!: string;
  loadingSubtitle!: string;

  constructor(
    private router: Router,
    private appService: AppService,
    private translocoService: TranslocoService,
    private storageService: StorageService,
    private securityService: SecurityService,
    private changeDetector: ChangeDetectorRef
  ) {
    this.storageService.clearData();
    router.events
      .pipe(
        filter(
          (event): event is NavigationEnd => event instanceof NavigationEnd
        )
      )
      .subscribe(() => {
        this.showNavigation =
          this.router.routerState.snapshot.url !== '/' + paths.common.notfound;
      });

    this.application = new Application(
      'FCS-C',
      'FCS-C',
      'assets/logo/FCSC-logo.svg',
      []
    );
  }

  translatePage() {
    this.translocoService
      .selectTranslate('components.navbar.data-management')
      .subscribe((resp) => {
        this.dataMang = resp;
        this.application.menus = [
          new Menu(
            this.dataMang,
            'uploadtool',
            true,
            [entitlements.VUS.FLASHWARE_CALCULATION],
            [
              new Menu(
                this.translocoService.translate('components.navbar.test-data'),
                'uploadtool/test/vp5-test',
                false,
                [entitlements.VUS.FCSC_VP5_TESTING],
                [
                  new Menu(
                    this.translocoService.translate(
                      'components.navbar.vp5-testing'
                    ),
                    'uploadtool/test/vp5-test',
                    false,
                    [entitlements.VUS.FCSC_VP5_TESTING]
                  ),
                ]
              ),

              new Menu(
                this.translocoService.translate(
                  'components.navbar.preliminary-data'
                ),
                'uploadtool/vorab',
                false,
                [entitlements.VUS.FCSC_VORAB_DATA]
              ),
              new Menu(
                this.translocoService.translate(
                  'components.navbar.series-data'
                ),
                'uploadtool/serien',
                false,
                [entitlements.VUS.FLASHWARE_CALCULATION],
                [
                  new Menu(
                    this.translocoService.translate(
                      'components.navbar.series-bdp'
                    ),
                    'uploadtool/serien/serien-bdp',
                    false,
                    [entitlements.VUS.FLASHWARE_CALCULATION]
                  ),
                  new Menu(
                    this.translocoService.translate(
                      'components.navbar.series-upload-list'
                    ),
                    'uploadtool/serien/upload-list',
                    false,
                    [entitlements.VUS.FLASHWARE_CALCULATION]
                  ),
                ]
              ),
            ],
            'uploadtool/vorab'
          ),
          new Menu(
            this.translocoService.translate(
              'components.navbar.expert-function'
            ),
            'experttool',
            false,
            [entitlements.VUS.FLASHWARE_CALCULATION],
            [
              new Menu(
                this.translocoService.translate(
                  'components.navbar.flashware-detection'
                ),
                'experttool/flashware',
                false,
                [entitlements.VUS.FLASHWARE_CALCULATION]
              ),
              new Menu(
                this.translocoService.translate(
                  'components.navbar.log-history'
                ),
                'experttool/log-history',
                false,
                [entitlements.VUS.FCSC_LOGS]
              ),
            ],
            'experttool/flashware'
          ),

          new Menu(
            this.translocoService.translate('components.navbar.admin-section'),
            'admin',
            false,
            [entitlements.VUS.FCSC_FLASHWARE_CALCULATION_CONFIGURATION],
            [
              new Menu(
                this.translocoService.translate(
                  'components.navbar.series-config'
                ),
                'admin/series-config',
                false,
                [entitlements.VUS.FCSC_FLASHWARE_CALCULATION_CONFIGURATION]
              ),
              new Menu(
                this.translocoService.translate('components.navbar.code-list'),
                'admin/code-list',
                false,
                [entitlements.VUS.FCSC_FLASHWARE_CALCULATION_CONFIGURATION]
              ),
              new Menu(
                this.translocoService.translate(
                  'components.navbar.notification-management'
                ),
                'admin/notification-management',
                false,
                [entitlements.VUS.FCSC_FLASHWARE_CALCULATION_CONFIGURATION]
              ),
              new Menu(
                'FSF Logs',
                'admin/fsf-logs',
                false,
                [entitlements.VUS.FCSC_FSF_LOGS]
              ),
              new Menu(
                this.translocoService.translate(
                  'components.navbar.vp5-versions'
                ),
                'admin/vp-versions',
                false,
                [entitlements.VUS.FCSC_VP5_VERSION]
              ),
              new Menu(
                this.translocoService.translate(
                  'components.navbar.testautomation'
                ),
                'admin/testautomation',
                false,
                [entitlements.VUS.FCSC_TESTINGBUTTON]
              ),
            ],
            'admin/series-config'
          ),
        ];
      });
  }

  ngOnInit(): void {
    this.translatePage();

    this.appService?.spinnerSubject?.subscribe((spinnerObject: Spinner) => {
      this.loadingTitle = spinnerObject.loadingTitle;
      this.loadingSubtitle = spinnerObject.loadingSubtitle;
      this.isLoading = spinnerObject.isLoading;
    });

    // entitlement check - enable and test later
    // if (
    //   this.securityService
    //     .getEntitlements()
    //     .some(
    //       () =>
    //         this.securityService
    //           .getEntitlements()
    //           .includes(entitlements.VUS.FCSC_VORAB_DATA) === false
    //     ) // === false
    // ) {
    //   this.router.navigate(['experttool/flashware']);
    // }
  }

  ngAfterContentChecked(): void {
    this.changeDetector.detectChanges();
  }
}
